import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import TaiSanService from "services/Asset/List/TaiSanService";

export const getTaiSan = createAsyncThunk(
  "taiSan/getTaiSan",
  async (data, { rejectWithValue }) => {
    try {
      const response = await TaiSanService.Get(data);
      return response.data;
    } catch (err) {
      console.error("API call failed:", err);
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const exportTaiSan = createAsyncThunk(
  "taiSan/exportTaiSan",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await TaiSanService.exportTaiSan(data);
      if (onSuccess) onSuccess(response);
      return response.data;
    } catch (err) {
      console.error("API call failed:", err);
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const importTaiSanByTransId = createAsyncThunk(
  "taiSan/importTaiSanByTransId",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess, id } = data;
      const response = await TaiSanService.importTaiSan(id);
      if (onSuccess) onSuccess(response);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const DownloadFileAsset = createAsyncThunk(
  "taiSan/DownloadFileAsset",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const response = await TaiSanService.downloadFile();
      if (onSuccess) onSuccess(response);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const UpdateImportSynceAsset = createAsyncThunk(
  "taiSan/UpdateImportSynceAsset",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess, ...payload } = data;
      const response = await TaiSanService.importSynce(payload);
      if (onSuccess) onSuccess(response);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const updateTaiSan = createAsyncThunk(
  "taiSan/updateTaiSan",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await TaiSanService.Update(data);
      if (onSuccess) onSuccess(response);
      return response.data;
    } catch (error) {
      console.error("API call failed:", error);
      return rejectWithValue(error.message || "Error");
    }
  }
);

export const createTaiSan = createAsyncThunk(
  "taiSan/createTaiSan",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await TaiSanService.Create(data);
      if (onSuccess) onSuccess(response.data);
      return response.data;
    } catch (error) {
      console.error("API call failed:", error);
      return rejectWithValue(error.description || "Error");
    }
  }
);

export const deleteTaiSan = createAsyncThunk(
  "taiSan/deleteTaiSan",
  async (data, { rejectWithValue }) => {
    const { onSuccess, id } = data;
    try {
      const response = await TaiSanService.Delete(id);
      if (onSuccess) onSuccess(response);
      return response.data;
    } catch (error) {
      console.error("API call failed:", error);
      return rejectWithValue(error.message || "Error");
    }
  }
);
export const getTaiSanById = createAsyncThunk(
  "taiSan/getTaiSanById",
  async (data, { rejectWithValue }) => {
    const { onSuccess, id } = data;
    try {
      const response = await TaiSanService.GetById(id);
      if (onSuccess) onSuccess(response);
      return response.data;
    } catch (error) {
      console.error("API call failed:", error);
      return rejectWithValue(error.message || "Error");
    }
  }
);

export const getTaiSanCoTheCapPhat = createAsyncThunk(
  "taiSan/getTaiSanCoTheCapPhat",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await TaiSanService.GetTaiSanCoTheCapPhat(payload);
      if (onSuccess) onSuccess(response.data);
      return response.data;
    } catch (error) {
      console.error("API call failed:", error);
      return rejectWithValue(error.message || "Error");
    }
  }
);

export const getQuaTrinhSuDung = createAsyncThunk(
  "taiSan/getQuaTrinhSuDung",
  async (data, { rejectWithValue }) => {
    try {
      const response = await TaiSanService.GetQuaTrinhSuDung(data);
      return response.data;
    } catch (err) {
      console.error("API call failed:", err);
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const getMaxMaTaiSanByLoaiTaiSanId = createAsyncThunk(
  "taiSan/getMaxMaTaiSanByLoaiTaiSanId",
  async (data, { rejectWithValue }) => {
    try {
      const { payload, onSuccess } = data;
      const response = await TaiSanService.GetMaxLoaiTaiSan(payload);
      if (onSuccess) onSuccess(response.data);
      return response.data;
    } catch (err) {
      console.error("API call failed:", err);
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const getThongTinTaiSanById = createAsyncThunk(
  "taiSan/getThongTinTaiSanById",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess, id } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await TaiSanService.GetThongTinTaiSanById(id);
      if (onSuccess) onSuccess(response.data);
      return response.data;
    } catch (error) {
      console.error("API call failed:", error);
      return rejectWithValue(error.message || "Error");
    }
  }
);

export const getThongTinPublicTaiSanById = createAsyncThunk(
  "taiSan/getThongTinPublicTaiSanById",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess, id } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await TaiSanService.GetThongTinPublicTaiSanById(id);
      if (onSuccess) onSuccess(response.data);
      return response.data;
    } catch (error) {
      console.error("API call failed:", error);
      return rejectWithValue(error.message || "Error");
    }
  }
);

export const createQuyDinhBaoDuong = createAsyncThunk(
  "taiSan/createQuyDinhBaoDuong",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await TaiSanService.CreateQuyDinhBaoDuong(payload);
      if (onSuccess) onSuccess(response.data);
      return response.data;
    } catch (error) {
      console.error("API call failed:", error);
      return rejectWithValue(error.message || "Error");
    }
  }
);

export const updateQuyDinhBaoDuong = createAsyncThunk(
  "taiSan/updateQuyDinhBaoDuong",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await TaiSanService.UpdateQuyDinhBaoDuong(payload);
      if (onSuccess) onSuccess(response.data);
      return response.data;
    } catch (error) {
      console.error("API call failed:", error);
      return rejectWithValue(error.message || "Error");
    }
  }
);

export const deleteQuyDinhBaoDuongById = createAsyncThunk(
  "taiSan/deleteQuyDinhBaoDuongById",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess, id } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await TaiSanService.DeleteQuyDinhBaoDuongByID(id);
      if (onSuccess) onSuccess(response.data);
      return response.data;
    } catch (error) {
      console.error("API call failed:", error);
      return rejectWithValue(error.message || "Error");
    }
  }
);


export const deleteQuyDinhBaoDuongChiTietById = createAsyncThunk(
  "taiSan/deleteQuyDinhBaoDuongChiTietById",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess, id } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await TaiSanService.DeleteQuyDinhBaoDuongChiTietByID(id);
      if (onSuccess) onSuccess(response.data);
      return response.data;
    } catch (error) {
      console.error("API call failed:", error);
      return rejectWithValue(error.message || "Error");
    }
  }
);


export const getQuyDinhBaoDuongById = createAsyncThunk(
  "taiSan/getQuyDinhBaoDuongById",
  async (id, { rejectWithValue }) => {
    try {
      const response = await TaiSanService.GetQuyDinhBaoDuongByID(id);
      return response.data.data;
    } catch (error) {
      console.error("API call failed:", error);
      return rejectWithValue(error.message || "Error");
    }
  }
);

export const getQuyDinhBaoDuongByTaiSanId = createAsyncThunk(
  "taiSan/getQuyDinhBaoDuongByTaiSanId",
  async (id, { rejectWithValue }) => {
    try {
      const response = await TaiSanService.GetQuyDinhBaoDuongByTaiSanID(id);
      return response.data.data;
    } catch (error) {
      console.error("API call failed:", error);
      return rejectWithValue(error.message || "Error");
    }
  }
);

export const getDanhSachTaiSanCanBaoDuong = createAsyncThunk(
  "taiSan/getDanhSachTaiSanCanBaoDuong",
  async (data, { rejectWithValue }) => {
    try {
      const response = await TaiSanService.GetDanhSachTaiSanCanBaoDuong(data);
      return response.data.data;
    } catch (error) {
      console.error("API call failed:", error);
      return rejectWithValue(error.message || "Error");
    }
  }
);

export const getDanhSachTaiSanDangBaoDuong = createAsyncThunk(
  "taiSan/getDanhSachTaiSanDangBaoDuong",
  async (data, { rejectWithValue }) => {
    try {
      const response = await TaiSanService.GetDanhSachTaiSanDangBaoDuong(data);
      return response.data.data;
    } catch (error) {
      console.error("API call failed:", error);
      return rejectWithValue(error.message || "Error");
    }
  }
);

export const getDanhSachNoiDungBaoDuong = createAsyncThunk(
  "taiSan/getDanhSachNoiDungBaoDuong",
  async (data, { rejectWithValue }) => {
    try {
      const response = await TaiSanService.GetDanhSachNoiDungBaoDuong(data);
      return response.data.data;
    } catch (error) {
      console.error("API call failed:", error);
      return rejectWithValue(error.message || "Error");
    }
  }
);

export const createBaoDuong = createAsyncThunk(
  "taiSan/createBaoDuong",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await TaiSanService.CreateBaoDuong(payload);
      if (onSuccess) onSuccess(response.data);
      return response.data;
    } catch (error) {
      console.error("API call failed:", error);
      return rejectWithValue(error.message || "Error");
    }
  }
);

export const hoanThanhBaoDuong = createAsyncThunk(
  "taiSan/hoanThanhBaoDuong",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await TaiSanService.HoanThanhBaoDuong(payload);
      if (onSuccess) onSuccess(response.data);
      return response.data;
    } catch (error) {
      console.error("API call failed:", error);
      return rejectWithValue(error.message || "Error");
    }
  }
);

export const getBaoDuongById = createAsyncThunk(
  "taiSan/getBaoDuongById",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess, id } = data;
      const response = await TaiSanService.GetBaoDuongById(id);
      if (onSuccess) onSuccess(response.data);
      return response.data.data;
    } catch (error) {
      console.error("API call failed:", error);
      return rejectWithValue(error.message || "Error");
    }
  }
);

export const searchPhieuBaoDuong = createAsyncThunk(
  "taiSan/searchPhieuBaoDuong",
  async (data, { rejectWithValue }) => {
    try {
      const response = await TaiSanService.SearchPhieuBaoDuong(data);
      return response.data.data;
    } catch (error) {
      console.error("API call failed:", error);
      return rejectWithValue(error.message || "Error");
    }
  }
);

export const updatePhieuBaoDuong = createAsyncThunk(
  "taiSan/updatePhieuBaoDuong",
  async (data, { rejectWithValue }) => {
    try {
      const response = await TaiSanService.UpdatePhieuBaoDuong(data);
      return response.data.data;
    } catch (error) {
      console.error("API call failed:", error);
      return rejectWithValue(error.message || "Error");
    }
  }
);

export const updateChiTietBaoDuong = createAsyncThunk(
  "taiSan/updateChiTietBaoDuong",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await TaiSanService.UpdateChiTietBaoDuong(data);
      if (onSuccess) onSuccess(response.data);
      return response.data.data;
    } catch (error) {
      console.error("API call failed:", error);
      return rejectWithValue(error.message || "Error");
    }
  }
);

export const deletePhieuBaoDuong = createAsyncThunk(
  "taiSan/deletePhieuBaoDuong",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess, id } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await TaiSanService.DeletePhieutBaoDuong(id);
      if (onSuccess) onSuccess(response.data);
      return response.data.data;
    } catch (error) {
      console.error("API call failed:", error);
      return rejectWithValue(error.message || "Error");
    }
  }
);

export const deleteChiTietPhieuBaoDuong = createAsyncThunk(
  "taiSan/deleteChiTietPhieuBaoDuong",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess, id } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await TaiSanService.DeleteChiTietBaoDuong(id);
      if (onSuccess) onSuccess(response.data);
      return response.data.data;
    } catch (error) {
      console.error("API call failed:", error);
      return rejectWithValue(error.message || "Error");
    }
  }
);

export const getDanhSachNoiDungBaoDuongTheoLoaiByBranchId = createAsyncThunk(
  "taiSan/getDanhSachNoiDungBaoDuongTheoLoaiByBranchId",
  async (data, { rejectWithValue }) => {
    try {

      const response = await TaiSanService.GetDanhSachNoiDungBaoDuongTheoLoaiByBranchId(data);
      return response.data.data;
    } catch (error) {
      console.error("API call failed:", error);
      return rejectWithValue(error.message || "Error");
    }
  }
);

export const getQuyDinhBaoDuongChiTietByID = createAsyncThunk(
  "taiSan/getQuyDinhBaoDuongChiTietByID",
  async (data, { rejectWithValue }) => {
    try {

      const response = await TaiSanService.GetQuyDinhBaoDuongChiTietByID(data);
      return response.data.data;
    } catch (error) {
      console.error("API call failed:", error);
      return rejectWithValue(error.message || "Error");
    }
  }
);

export const getTongHopQuaTrinhSuDung = createAsyncThunk(
  "taiSan/getTongHopQuaTrinhSuDung",
  async (data, { rejectWithValue }) => {
    try {

      const response = await TaiSanService.GetTongHopQuaTrinhSuDung(data);
      return response.data.data;
    } catch (error) {
      console.error("API call failed:", error);
      return rejectWithValue(error.message || "Error");
    }
  }
);



const initialState = {
  loading: false,
  taiSanList: [],
  taiSanByIds: [],
  taiSanDetail: {},
  quaTrinhSuDungList: [],
  mataisanmax: null,
  danhsachtaisancothethuhoi: [],
  thongTinTaiSan: {},
  quyDinhBaoDuongDeTail: {},
  quyDinhBaoDuongList: [],
  thongTinChiTietTaiSanList: [],
  linhKienList: [],
  thongTinPhanBo: {},
  danhSachTaiSanCanBaoDuong: [],
  danhSachTaiSanDangBaoDuong: [],
  baoDuongDeTail: {},
  danhSachPhieuBaoDuong: [],
  dsChiTieTaiSanBaoDuong: [],
  taiSanDetailPublic:{},
  quyDinhBaoDuongListPublic:[],
  thongTinTaiSanPublic:{},
  linhKienListPublic:[],
  thongTinChiTietTaiSanListPublic:[],
  thongTinPhanBoPublic:{},
  tongHopQuaTrinhSuDung:[]


};

const taiSanSlice = createSlice({
  name: "taiSan",
  initialState,
  reducers: {
    resetTaiSanDetail: (state) => {
      state.taiSanDetail = {
        data: null,
        loading: false,
      };
    },
    resetBaoDuongDetail: (state) => {
      state.baoDuongDeTail = {};
    },
    resetdsChiTieTaiSanBaoDuong:(state)=>{
      state.dsChiTieTaiSanBaoDuong=[]
    },
    resetquyDinhBaoDuongList:(state)=>{
      state.quyDinhBaoDuongList=[]
    },
    resetquyDinhBaoDuongDeTail:(state)=>{
      state.quyDinhBaoDuongDeTail={}
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTaiSan.pending, (state) => {
        state.loading = true;
      })
      .addCase(getTaiSan.fulfilled, (state, action) => {
        state.loading = false;
        state.taiSanList = action.payload;
      })
      .addCase(getTaiSan.rejected, (state) => {
        state.loading = false;
      })
      .addCase(exportTaiSan.pending, (state) => {
        state.loading = true;
      })
      .addCase(exportTaiSan.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(exportTaiSan.rejected, (state) => {
        state.loading = false;
      })
      .addCase(updateTaiSan.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateTaiSan.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(updateTaiSan.rejected, (state) => {
        state.loading = false;
      })
      .addCase(createTaiSan.pending, (state) => {
        state.loading = true;
      })
      .addCase(createTaiSan.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(createTaiSan.rejected, (state) => {
        state.loading = false;
      })
      .addCase(deleteTaiSan.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteTaiSan.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(deleteTaiSan.rejected, (state) => {
        state.loading = false;
      })

      .addCase(getTaiSanById.pending, (state) => {
        state.loading = true;
      })
      .addCase(getTaiSanById.fulfilled, (state, action) => {
        state.loading = false;
        state.taiSanDetail = action.payload;
      })
      .addCase(getTaiSanById.rejected, (state) => {
        state.loading = false;
      })

      .addCase(getQuaTrinhSuDung.pending, (state) => {
        state.loading = true;
      })
      .addCase(getQuaTrinhSuDung.fulfilled, (state, action) => {
        state.loading = false;
        state.quaTrinhSuDungList = action.payload;
      })
      .addCase(getQuaTrinhSuDung.rejected, (state) => {
        state.loading = false;
      })

      .addCase(getTaiSanCoTheCapPhat.pending, (state) => {
        state.loading = true;
      })
      .addCase(getTaiSanCoTheCapPhat.fulfilled, (state, action) => {
        state.loading = false;
        state.taiSanByIds = action.payload;
      })
      .addCase(getTaiSanCoTheCapPhat.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getMaxMaTaiSanByLoaiTaiSanId.pending, (state) => {
        state.loading = true;
      })
      .addCase(getMaxMaTaiSanByLoaiTaiSanId.fulfilled, (state, action) => {
        state.loading = false;
        state.mataisanmax = action.payload;
      })
      .addCase(getMaxMaTaiSanByLoaiTaiSanId.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getThongTinTaiSanById.pending, (state) => {
        state.loading = true;
      })
      .addCase(getThongTinTaiSanById.fulfilled, (state, action) => {
        state.loading = false;
        state.taiSanDetail = action.payload;
        state.quyDinhBaoDuongList = action.payload?.quyDinhBaoDuong;
        state.thongTinTaiSan = action.payload.thongTinTaiSan;
        state.linhKienList = action.payload.linhKien;
        state.thongTinChiTietTaiSanList = action.payload?.chiTietTaiSan;
        state.thongTinPhanBo = action.payload?.thongTinPhanBo;
      })
      .addCase(getThongTinTaiSanById.rejected, (state) => {
        state.loading = false;
      })
      .addCase(createQuyDinhBaoDuong.pending, (state) => {
        state.loading = true;
      })
      .addCase(createQuyDinhBaoDuong.fulfilled, (state, action) => {
        state.loading = false;
        state.quyDinhBaoDuongList = action.payload;
      })
      .addCase(createQuyDinhBaoDuong.rejected, (state) => {
        state.loading = false;
      })
      .addCase(updateQuyDinhBaoDuong.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateQuyDinhBaoDuong.fulfilled, (state, action) => {
        state.loading = false;
        state.quyDinhBaoDuongDeTail = action.payload;
      })
      .addCase(updateQuyDinhBaoDuong.rejected, (state) => {
        state.loading = false;
      })
      .addCase(deleteQuyDinhBaoDuongById.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteQuyDinhBaoDuongById.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(deleteQuyDinhBaoDuongById.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getQuyDinhBaoDuongById.pending, (state) => {
        state.loading = true;
      })
      .addCase(getQuyDinhBaoDuongById.fulfilled, (state, action) => {
        state.loading = false;
        state.quyDinhBaoDuongDeTail = action.payload;
      })
      .addCase(getQuyDinhBaoDuongById.rejected, (state) => {
        state.loading = false;
      })

      .addCase(getQuyDinhBaoDuongByTaiSanId.pending, (state) => {
        state.loading = true;
      })
      .addCase(getQuyDinhBaoDuongByTaiSanId.fulfilled, (state, action) => {
        state.loading = false;
        state.quyDinhBaoDuongList = action.payload;
      })
      .addCase(getQuyDinhBaoDuongByTaiSanId.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getDanhSachTaiSanCanBaoDuong.pending, (state) => {
        state.loading = true;
      })
      .addCase(getDanhSachTaiSanCanBaoDuong.fulfilled, (state, action) => {
        state.loading = false;
        state.danhSachTaiSanCanBaoDuong = action.payload;
      })
      .addCase(getDanhSachTaiSanCanBaoDuong.rejected, (state) => {
        state.loading = false;
      })
      .addCase(createBaoDuong.pending, (state) => {
        state.loading = true;
      })
      .addCase(createBaoDuong.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(createBaoDuong.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getDanhSachTaiSanDangBaoDuong.pending, (state) => {
        state.loading = true;
      })
      .addCase(getDanhSachTaiSanDangBaoDuong.fulfilled, (state, action) => {
        state.loading = false;
        state.danhSachTaiSanDangBaoDuong = action.payload;
      })
      .addCase(getDanhSachTaiSanDangBaoDuong.rejected, (state) => {
        state.loading = false;
      })
      .addCase(hoanThanhBaoDuong.pending, (state) => {
        state.loading = true;
      })
      .addCase(hoanThanhBaoDuong.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(hoanThanhBaoDuong.rejected, (state) => {
        state.loading = false;
      })

      .addCase(getBaoDuongById.pending, (state) => {
        state.loading = true;
      })
      .addCase(getBaoDuongById.fulfilled, (state, action) => {
        state.loading = false;
        state.baoDuongDeTail = action.payload;
        state.dsChiTieTaiSanBaoDuong = action.payload.chiTiet;
      })
      .addCase(getBaoDuongById.rejected, (state) => {
        state.loading = false;
      })
      .addCase(searchPhieuBaoDuong.pending, (state) => {
        state.loading = true;
      })
      .addCase(searchPhieuBaoDuong.fulfilled, (state, action) => {
        state.loading = false;
        state.danhSachPhieuBaoDuong = action.payload;
      })
      .addCase(searchPhieuBaoDuong.rejected, (state) => {
        state.loading = false;
      })

      .addCase(updatePhieuBaoDuong.pending, (state) => {
        state.loading = true;
      })
      .addCase(updatePhieuBaoDuong.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(updatePhieuBaoDuong.rejected, (state) => {
        state.loading = false;
      })

      .addCase(updateChiTietBaoDuong.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateChiTietBaoDuong.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(updateChiTietBaoDuong.rejected, (state) => {
        state.loading = false;
      })

      .addCase(deletePhieuBaoDuong.pending, (state) => {
        state.loading = true;
      })
      .addCase(deletePhieuBaoDuong.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(deletePhieuBaoDuong.rejected, (state) => {
        state.loading = false;
      })

      .addCase(deleteChiTietPhieuBaoDuong.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteChiTietPhieuBaoDuong.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(deleteChiTietPhieuBaoDuong.rejected, (state) => {
        state.loading = false;
      })
     
      .addCase(getDanhSachNoiDungBaoDuong.pending, (state) => {
        state.loading = true;
      })
      .addCase(getDanhSachNoiDungBaoDuong.fulfilled, (state, action) => {
        state.loading = false;
        state.quyDinhBaoDuongList = action.payload
      })
      .addCase(getDanhSachNoiDungBaoDuong.rejected, (state) => {
        state.loading = false;
      })

      .addCase(deleteQuyDinhBaoDuongChiTietById.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteQuyDinhBaoDuongChiTietById.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(deleteQuyDinhBaoDuongChiTietById.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getThongTinPublicTaiSanById.pending, (state) => {
        state.loading = true;
      })
      .addCase(getThongTinPublicTaiSanById.fulfilled, (state, action) => {
        state.loading = false;
        state.taiSanDetailPublic = action.payload;
        state.quyDinhBaoDuongListPublic = action.payload?.quyDinhBaoDuong;
        state.thongTinTaiSanPublic = action.payload.thongTinTaiSan;
        state.linhKienListPublic = action.payload.linhKien;
        state.thongTinChiTietTaiSanList = action.payload?.chiTietTaiSan;
        state.thongTinPhanBoPublic = action.payload?.thongTinPhanBo;
      })
      .addCase(getThongTinPublicTaiSanById.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getDanhSachNoiDungBaoDuongTheoLoaiByBranchId.pending, (state) => {
        state.loading = true;
      })
      .addCase(getDanhSachNoiDungBaoDuongTheoLoaiByBranchId.fulfilled, (state, action) => {
        state.loading = false;
        state.quyDinhBaoDuongList = action.payload;

      })
      .addCase(getDanhSachNoiDungBaoDuongTheoLoaiByBranchId.rejected, (state) => {
        state.loading = false;
      })

      .addCase(getQuyDinhBaoDuongChiTietByID.pending, (state) => {
        state.loading = true;
      })
      .addCase(getQuyDinhBaoDuongChiTietByID.fulfilled, (state, action) => {
        state.loading = false;
        state.quyDinhBaoDuongDeTail= action.payload
      })
      .addCase(getQuyDinhBaoDuongChiTietByID.rejected, (state) => {
        state.loading = false;
      })

      .addCase(getTongHopQuaTrinhSuDung.pending, (state) => {
        state.loading = true;
      })
      .addCase(getTongHopQuaTrinhSuDung.fulfilled, (state, action) => {
        state.loading = false;
        state.tongHopQuaTrinhSuDung= action.payload
      })
      .addCase(getTongHopQuaTrinhSuDung.rejected, (state) => {
        state.loading = false;
      })


      ;
  },
});
export const { resetTaiSanDetail, resetBaoDuongDetail, resetdsChiTieTaiSanBaoDuong, resetquyDinhBaoDuongList, resetquyDinhBaoDuongDeTail } = taiSanSlice.actions;

export default taiSanSlice.reducer;
