import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import TrinhDoHocVanService from "services/employee/TrinhDoHocVanService";

export const getTenTruongTotNghiep = createAsyncThunk(
  "trinhDoHocVan/getTenTruongTotNghiep",
  async (data, { rejectWithValue }) => {
    try {
      const response = await TrinhDoHocVanService.getDanhSachTruongTotNghiep();
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);
export const getByEmployeeId = createAsyncThunk(
  "trinhDoHocVan/getByEmployeeId",
  async (id, { rejectWithValue }) => {
    try {
      const response = await TrinhDoHocVanService.getByEmployeeId(id);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const upSert = createAsyncThunk(
  "trinhDoHocVan/upSert",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await TrinhDoHocVanService.upsert(payload);
      if (onSuccess) onSuccess(response);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const deleteTrinhDoHocVan = createAsyncThunk(
  "trinhDoHocVan/delete",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess, id } = data;
      const response = await TrinhDoHocVanService.delete(id);
      if (onSuccess) onSuccess(response);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

const initialState = {
  loading: false,
  listTruongTotNghiep: [],
  listHocVan: {},
};

export const trinhDoHocVanSlice = createSlice({
  name: "trinhDoHocVan",
  initialState,
  reducers: {
    showLoading: (state) => {
      state.loading = true;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getByEmployeeId.pending, (state) => {
        state.loading = true;
      })
      .addCase(getByEmployeeId.fulfilled, (state, action) => {
        state.loading = false;
        state.listHocVan = action.payload;
      })
      .addCase(getByEmployeeId.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(upSert.pending, (state) => {
        state.loading = true;
      })
      .addCase(upSert.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(upSert.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(deleteTrinhDoHocVan.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteTrinhDoHocVan.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(deleteTrinhDoHocVan.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getTenTruongTotNghiep.pending, (state) => {
        state.loading = true;
      })
      .addCase(getTenTruongTotNghiep.fulfilled, (state, action) => {
        state.loading = false;
        state.listTruongTotNghiep = action.payload;
      })
      .addCase(getTenTruongTotNghiep.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export const { showLoading } = trinhDoHocVanSlice.actions;

export default trinhDoHocVanSlice.reducer;
