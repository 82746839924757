export const guidEmpty = "00000000-0000-0000-0000-000000000000";

export const FILTER_TIME_ID = {
  Yesterday: 1,
  Today: 2,
  ThisWeek: 3,
  ThisMonth: 4,
  LastMonth: 5,
  ThisYear: 6,
};

export const FILTER_TIME_BY_BRANCH = [
  { value: FILTER_TIME_ID.Yesterday, label: "Hôm qua" },
  { value: FILTER_TIME_ID.Today, label: "Hôm nay" },
  { value: FILTER_TIME_ID.ThisWeek, label: "Tuần này" },
  { value: FILTER_TIME_ID.ThisMonth, label: "Tháng này" },
  { value: FILTER_TIME_ID.LastMonth, label: "Tháng trước" },
  { value: FILTER_TIME_ID.ThisYear, label: "Năm nay" },
];

export const FILTER_TIME_BY_ALL_BRANCH = [
  { value: FILTER_TIME_ID.ThisWeek, label: "Tuần này" },
  { value: FILTER_TIME_ID.ThisMonth, label: "Tháng này" },
  { value: FILTER_TIME_ID.ThisYear, label: "Năm này" },
];

export const STATUS_RATING = [
  { value: 1, label: "Rất Kém", color: "#ff6b72" },
  { value: 2, label: "Cần cải thiện", color: "#fa8c16" },
  { value: 3, label: "Bình thường", color: "#ffc107" },
  { value: 4, label: "Hài lòng", color: "#04d182" },
  { value: 5, label: "Rất hài lòng", color: "#3e82f7" },
];

export const GENDER = [
  { value: 1, label: "Nam" },
  { value: 2, label: "Nữ" },
  { value: 3, label: "Khác" },
];

export const dateFormatList = [
  "DD/MM/YYYY",
  "DD/MM/YY",
  "DD-MM-YYYY",
  "DD-MM-YY",
  "DDMMYYYY",
  "DDMMYY",
];

export const dateTimeFormatList = [
  "DD/MM/YYYY HH:mm",
  "DD/MM/YY HH:mm",
  "DD-MM-YYYY HH:mm",
  "DD-MM-YY HH:mm",
];

export const EMaLoaiKK = [
  { value: 1, label: "Kho" },
  { value: 2, label: "Khoa" },
  { value: 3, label: "Hành chính" },
  { value: 4, label: "Tủ trực" },
  { value: 5, label: "Nhà cung cấp" },
];

export const EThietLapKK = [
  { value: -1, label: "Không sử dụng" },
  { value: 0, label: "Mặc định" },
  { value: 1, label: "Khoa khám bệnh" },
  { value: 2, label: "Khoa điều trị" },
  { value: 3, label: "Nhà thuốc" },
  { value: 4, label: "kho tồn cũ" },
];

export const QuyenThaoTac = [
  { id: 1, name: "Thêm" },
  { id: 2, name: "Sửa" },
  { id: 3, name: "Xóa" },
  { id: 4, name: "In" },
  { id: 5, name: "Sao chép" },
];

export const PermitArr = [
  { name: "Them", value: 1 },
  { name: "Sua", value: 2 },
  { name: "Xoa", value: 4 },
  { name: "In", value: 8 },
  { name: "Xem", value: 16 },
  { name: "ChoPhep", value: 32 },
  { name: "Khoa", value: 64 },
];

export const PersonnelForm = [
  { value: 1, label: "Nhân viên công ty/tập đoàn" },
  { value: 2, label: "Nhân viên để tên" },
  { value: 3, label: "Thuê chứng chỉ" },
];
export const HoSoDeTenArr = [
  { value: 1, label: "Có HS để tên" },
  { value: 2, label: "Không có HS để tên (làm công BHXH)" },
];
export const SalaryBaseData = [
  { value: 1, label: "Lương cơ bản" },
  { value: 2, label: "Lương thỏa thuận" },
];
export const KyHieuChamCongType = [
  { value: 1, label: "Chấm công" },
  { value: 2, label: "Nghỉ phép" },
  { value: 3, label: "Nghỉ phép cưới" },
  { value: 4, label: "Nghỉ phép Tết" },
  { value: 5, label: "Đào tạo" },
];

export const PermitValue = {
  them: 1,
  sua: 2,
  xoa: 4,
  in: 8,
  xem: 16,
  chophep: 32,
  khoa: 64,
};
export const permitKey = {
  hrm_category: 1201,
  hrm_employee: 1202,
  hrm_department_setting: 1203,
  hrm_bhyt_dsnv: 1204,
  // Thiet lap ho so
  hrm_hoso: 1205,
  hrm_thietlapbangcong: 1206,
  hrm_danhsachbangcong: 1207,
  hrm_noibo: 1208,
  hrm_quanlycong: 1209,
  hrm_quanlynam: 1210,
  hrm_quanlynghi: 1211,
  hrm_hosonhanvien: 1212,
  // Luong area
  hrm_thietLapLuong: 1250,
  hrm_quanlyluong: 1251,
  hrm_duyetbangluong: 1252,
  // end Luong Area
  hrm_listContract: 1231,
  hrm_settingContract: 1230,
  hrm_listTraining: 1241,
  hrm_manageTraining: 1242,
  hrm_settingTraining: 1240,
  hrm_manageOvertime: 1243,
  hrm_manageMealAllowance: 1244,
  hrm_dangKyCongCom: 1245,
  // Tuyen dung area
  hrm_danhgiathuviec: 1287,
  hrm_danhgiaungvien: 1288,
  hrm_hosoungvien: 1289,
  hrm_tuyendung: 1290,
  hrm_duyettuyendung: 1291,
  hrm_thietlaptuyendung: 1292,
  hrm_ghiNhanYeuCau: 1293,
  hrm_employeetransfer: 1294,
  hrm_thietLapKhac: 1295,
  hrm_employee_deten: 1296,
  hrm_employee_thuechungchi: 1297,
  hrm_donxacnhancong: 1298,
  hrm_techticketmanagement: 1299,

  // End tuyen dung area
  crm_report_qos: 1301, // bao cao hoat dong kham chua benh
  crm_service: 1302, // bao cao chat luong dich vu
  crm_hospital: 1303,
  crm_doctor: 1304,
  crm_kcb: 1305,
  crm_service_medical: 1306,
  crm_posts: 1307,
  crm_notification: 1308,
  crm_thietlap: 1309,
  crm_comment: 1310,
  crm_danhmucdichvutainha: 1311,
  crm_dichvutainha: 1312,
  crm_lichkhamlai: 1313,
  crm_thietlapdmksk: 1314,
  crm_khamsuckhoedoan: 1315,
  crm_thanhtoankskdoan: 1316,
  crm_congtacvien: 1317,
  crm_doankhamksk: 1318,
  crm_donhangksk: 1319,
  // Marketing campaign
  crm_marketingCampaign: 1330,
  crm_callHistory: 1331,

  crm_DanhMucKhachHang: 1332,
  crm_vote: 1333,

  identity_userBranch: 1101,
  identity_scope: 1102,
  identity_role: 1103,
  identity_user_role: 1104,
  project_quanlycongvanvanban: 1601,
  project_thietlap: 1602,
  // Quan ly Quy che
  project_regulationManage: 1604,
  project_quanLyVanBan: 1605,
  project_quanLyHoSo: 1606,
  project_regulationSetting: 1603,
  project_quanlyduan: 1690,
  project_duyetduan: 1691,
  project_quanlycongviec: 1692,
  //common
  common_signatureProcess: 1401,

  //sale
  sale_dexuatmuasam: 1501,
  pharma_quanlykho: 1502,
  pharma_banhang: 1503,
  pharma_common: 1504,
  pharma_thanhtoan: 1505,
  sale_tonghopdexuatmuasam: 1506,
  ke_hoach_mua_hang: 1507,
  //finance
  finance_danhmucdinhkhoan: 1801,
  finance_danhmucthuchi: 1802,
  finance_denghichi: 1803,
  finance_phieuchi: 1804,
  finance_denghithutien: 1805,
  finance_phieuthu: 1806,
  finance_denghiung: 1807,
  finance_phieuung: 1808,
  finance_kehoachthanhtoan: 1810,
  finance_isTrinhDeNghi: 1812,

  //Tài sản
  asset_settingTaiSan: 1901,
  asset_listTaiSan: 1902,
  asset_suaChuaTaiSan: 1903,
  asset_linhkien: 1910,
  asset_baoDuong: 1921,
  asset_kiemke: 1931,
  asset_capPhat: 1935,
  asset_thuHoi: 1936,
  asset_danhHong:1937,
  asset_deNghiThanhLy:1938,
};

export const SystemButtonSource = {
  addBtn: "addBtn",
  updateBtn: "updateBtn",
  deleteBtn: "deleteBtn",
  downloadBtn: "downloadBtn",
  refreshBtn: "refreshBtn",
  importBtn: "importBtn",
  import1Btn: "import1Btn",
  exportBtn: "exportBtn",
  export1Btn: "export1Btn",
  lockBtn: "lockBtn",
  unLockBtn: "unLockBtn",
  calculatorBtn: "calculatorBtn",
  approveBtn: "approveBtn",
  marketingBtn: "marketingBtn",
  editBtn: "editBtn",
  dollarBtn: "dollarBtn",
  closeBtn: "closeBtn",
  capPhatBtn: "capPhatBtn",
  thuHoiBtn: "thuHoiBtn",
  minusBtn: "minusBtn",
  qrBtn: "qrBtn",
  checkedBtn: "checkedBtn",
  exclamationOutlined:'exclamationOutlined',
  exclamationCircleOutlinedBtn: 'exclamationCircleOutlinedBtn'
};

export const TRAINING_STATUS_OPTION = [
  {
    id: 0,
    name: "Chưa bắt đầu",
    color: "gray",
  },
  {
    id: 1,
    name: "Đang đào tạo",
    color: "orange",
  },
  {
    id: 2,
    name: "Đã hoàn thành ",
    color: "green",
  },
  {
    id: 3,
    name: "Tạm dừng",
    color: "pink",
  },
  {
    id: 4,
    name: "Bị huỷ",
    color: "red",
  },
];
export const APPROVAL_OPTION = [
  {
    id: 0,
    value: true,
    name: "Đã duyệt",
    label: "Đã duyệt",
    color: "green",
  },
  {
    id: 1,
    value: false,
    name: "Chưa duyệt",
    label: "Chưa duyệt",
    color: "red",
  },
];
export const USING_OPTION = [
  {
    id: 0,
    value: false,
    name: "Đang sử dụng",
    color: "green",
  },
  {
    id: 1,
    value: true,
    name: "Ngừng sử dụng",
    color: "red",
  },
];
export const MA_APPROVAL_OPTION = [
  {
    id: 0,
    value: true,
    name: "Đã chốt",
    color: "green",
  },
  {
    id: 1,
    value: false,
    name: "Chưa chốt",
    color: "red",
  },
];

export const CONTRACT_STATUS_OPTION = [
  {
    id: 0,
    name: "Chưa hiệu lực",
    color: "orange",
  },
  {
    id: 1,
    name: "Đang hiệu lực",
    color: "green",
  },
  {
    id: 2,
    name: "Quá hạn",
    color: "red",
  },
  {
    id: 3,
    name: "Tạm dừng",
    color: "pink",
  },
];

export const GNYC_STATUS = {
  Huy: 0,
  DaXuLy: 1,
  ChoXuLy: 2,
  DangXuLy: 3,
  ChoTiepNhan: 4,
  DaNghiemThu: 5,
  TamHoan: -1,
};
export const GNYC_DOUUTIEN = {
  Thap: 0,
  TrungBinh: 1,
  Gap: 2,
  RatGap: 3,
};

export const GNYC_DOUUTIEN_OPTION = [
  {
    id: GNYC_DOUUTIEN.Thap,
    name: "Thấp",
    color: "gray",
  },
  {
    id: GNYC_DOUUTIEN.TrungBinh,
    name: "Trung bình",
    color: "yellow",
  },
  {
    id: GNYC_DOUUTIEN.Gap,
    name: "Gấp",
    color: "orange",
  },
  {
    id: GNYC_DOUUTIEN.RatGap,
    name: "Rất gấp",
    color: "red",
  },
];

export const GNYC_STATUS_OPTION = [
  {
    id: GNYC_STATUS.ChoTiepNhan,
    name: "Chờ tiếp nhận",
    color: "yellow",
  },
  {
    id: GNYC_STATUS.ChoXuLy,
    name: "Chờ xử lý",
    color: "orange",
  },
  {
    id: GNYC_STATUS.DangXuLy,
    name: "Đang xử lý",
    color: "gray",
  },
  {
    id: GNYC_STATUS.DaXuLy,
    name: "Đã xử lý",
    color: "blue",
  },
  {
    id: GNYC_STATUS.DaNghiemThu,
    name: "Đã nghiệm thu",
    color: "green",
  },
  {
    id: GNYC_STATUS.Huy,
    name: "Huỷ",
    color: "red",
  },
  {
    id: GNYC_STATUS.TamHoan,
    name: "Tạm hoãn",
    color: "pink",
  },
];

export const DOCUMENT_TYPE_ID = {
  DonXinNghiPhep: 1,
  PhieuTangCa: 2,
  PhieuDaoTao: 3,
  DeXuatTuyenDung: 4,
  HopDongLaoDong: 5,
  VanBanNoiBoDi: 6,
  PhieuQuyChe: 7,
  PhieuVanBan: 8,
  PhieuCom: 9,
  QuanLyDuAn: 10,
  DanhGiaUngVien: 11,
  HoSoTaiLieu: 12,
  DanhGiaThuViec: 13,
  HoSoUngVien: 14,
  QuanLyCongViec: 15,
  QuanLyCongVanBenNgoaiDi: 16,
  QuanLyCongVanBenNgoaiDen: 17,
  KskPhuongAnKinhDoanh: 18,
  KskThanhLyHopDong: 19,
  DeXuatMuaSam: 20,
  NhapHang: 21,
  XuatHang: 22,
  DonHangKsk: 23,
  LuanChuyen: 24,
  BanLe: 25,
  BanBuon: 26,
  DieuChuyenNhanVien: 27,
  DonXinNghiKhongLuong: 28,
  KeHoachMuaHang: 39,
  DonXacNhanCong: 30,
  DonXacNhanDieuDong: 31,
  DeNghiChiTien: 32,
  PhieuChiTien: 33,
  DeNghiThuTien: 34,
  PhieuThuTien: 35,
  DeNghiTamUng: 36,
  PhieuUngTien: 37,
  ChuyenTienNoiBo: 38,
  KeHoachThanhToan: 29,
  ChungTuNghiepVuKhac: 40,
  CapPhatTaiSan: 41,
  BaoHongTaiSan: 42,
  SuaChuaTaiSan: 43,
  ThuHoiTaiSan: 44,
  BaoMatTaiSan: 45,
  AssetDieuChuyen: 46,
  BaoDuongTaiSan: 50,
  HuyTaiSan:51,
  DeNghiThanhLy:52

};

export const DOCUMENT_TYPE_COMP = Object.entries(DOCUMENT_TYPE_ID).map(
  ([key, value]) => ({
    id: value,
    comp: key,
  })
);

// export const KIEU_VAN_BAN = [
//   { label: "Nội bộ", value: 1 },
//   { label: "Nơi khác chuyển đến", value: 2 },
// ];
export const HINHTHUC_CONG_VAN = {
  NoiBo: 1,
  BenNgoai: 2,
};

export const KIEU_CONG_VAN = {
  Den: 1,
  Di: 2,
};

export const SIGNATURE_STATUS = {
  Unsigned: 0,
  Signed: 1,
  Declined: 2,
};

export const TIEN_TRINH = [
  { label: "Chờ tiếp nhận", value: 1, mamau: "#f50" },
  { label: "Đang tiếp nhận", value: 2, mamau: "#2db7f5" },
  { label: "Đã tiếp nhận", value: 3, mamau: "#87d068" },
];

export const SIGN_VALUE = {
  Unsigned: 0,
  Signed: 1,
  Declined: 2,
};

export const SIGN_STATUS = [
  {
    name: "Tất cả",
    color: "gray",
  },
  {
    id: SIGN_VALUE.Unsigned,
    name: "Chưa ký",
    color: "orange",
  },
  {
    id: SIGN_VALUE.Signed,
    name: "Đã ký",
    color: "green",
  },
  {
    id: SIGN_VALUE.Declined,
    name: "Quá hạn",
    color: "red",
  },
];

export const SIGN_OPTION_STATUS = [
  {
    name: "Tất cả",
    value: 0,
  },
  {
    name: "Chờ ký",
    value: 1,
  },
  {
    name: "Chờ bạn ký",
    value: 2,
  },
  {
    name: "Bạn đã ký",
    value: 3,
  },
  {
    name: "Bị từ chối",
    value: 4,
  },
  {
    name: "Hoàn thành",
    value: 5,
  },
];

export const TRANG_THAI_KY = [
  { label: "Đã hoàn thành", value: 1, mamau: "#f50" },
  { label: "Đang tiếp nhận", value: 2, mamau: "#2db7f5" },
  { label: "Đang cập nhật", value: 0, mamau: "#87d068" },
];

export const EProjectDocumentType = {
  None: 0,
  QuyChe: 1,
  VanBan: 2,
  HoSo: 3,
  NhomQuyChe: 4,
};

export const DEXUAT_STATUS_OPTIONS = [
  { id: 0, color: "red", name: "Chưa duyệt" },
  { id: 1, color: "gray", name: "Chờ duyệt" },
  { id: 2, color: "yellow", name: "Đã duyệt" },
  { id: 3, color: "blue", name: "Đang tuyển" },
  { id: 4, color: "green", name: "Hoàn thành" },
  { id: 5, color: "purple", name: "Hoàn thành" },
];

export const HOSOUNGVIEN_STT_OPTIONS = [
  { id: 0, color: "gray", name: "Ứng tuyển" },
  { id: 1, color: "gray", name: "Đã liên hệ phỏng vấn" },
  { id: 2, color: "yellow", name: "Đã phỏng vấn" },
  { id: 3, color: "red", name: "Không trúng tuyển" },
  { id: 4, color: "green", name: "Trúng tuyển" },
  { id: 5, color: "orange", name: "Không nhận việc" },
  { id: 6, color: "orange", name: "Không phù hợp" },
  { id: 7, color: "orange", name: "Không tham gia PV" },
  { id: 8, color: "orange", name: "Khác" },
];

export const DANHGIAUNGVIEN_STT_OPTIONS = [
  { id: 1, color: "blue", name: "Chờ đánh giá" },
  { id: 2, color: "yellow", name: "Chờ xét duyệt" },
  { id: 3, color: "red", name: "Không trúng tuyển" },
  { id: 4, color: "green", name: "Trúng tuyển" },
];

export const DANHGIATHUVIEC_STT_OPTIONS = [
  { id: 0, color: "blue", name: "Đang thử việc" },
  { id: 1, color: "gray", name: "Nghỉ việc" },
  { id: 2, color: "green", name: "Chính thức" },
];

export const TRANG_THAI_UU_TIEN = [
  { id: 1, color: "green", name: "Bình thường" },
  { id: 2, color: "red", name: "Gấp" },
];

export const TONGHOPMUASAM_STT_OPTIONS = [
  { id: 0, color: "red", name: "Chờ xử lý" },
  { id: 1, color: "blue", name: "Đang xử lý" },
  { id: 2, color: "green", name: "Đã duyệt" },
  { id: 3, color: "green", name: "Đã nghiệm thu" },
  { id: 4, color: "orange", name: "Tạm hoãn" },
  { id: 5, color: "red", name: "Hủy" },
];

export const ERP_NOTIFICATION_TYPE = [
  {
    name: "Tất cả",
    value: 0,
  },
  {
    name: "Hệ thống",
    value: 1,
  },
  {
    name: "Phiếu ký",
    value: 2,
  },
  {
    name: "Nhân sự",
    value: 3,
  },
  {
    name: "Khách hàng",
    value: 4,
  },
  {
    name: "Công văn",
    value: 5,
  },
  {
    name: "Công việc",
    value: 6,
  },
];

export const TrangThaiCongCom = {
  None: 0,
  KhongDangKy: 1,
  ChuaChot: 2,
  DaChot: 3,
  CancelByEmployee: 4,
  CanelByAdmin: 5,
};
export const CongComDanhMuc = [
  {
    name: "Không đăng ký",
    value: TrangThaiCongCom.KhongDangKy,
    color: "grey",
  },
  {
    name: "Chưa chốt",
    value: TrangThaiCongCom.ChuaChot,
    color: "orange",
  },
  {
    name: "Đã chốt",
    value: TrangThaiCongCom.DaChot,
    color: "green",
  },
  {
    name: "Huỷ bởi nhân viên",
    value: TrangThaiCongCom.CancelByEmployee,
    color: "red",
  },
  {
    name: "Huỷ bởi quản trị viên",
    value: TrangThaiCongCom.CanelByAdmin,
    color: "red",
  },
];
export const CongComType = {
  ComTrua: 1,
  ComTruc: 2,
};
export const CONGCOMTYPE_OPTIONS = [
  {
    value: CongComType.ComTrua,
    name: "Cơm trưa",
  },
  {
    value: CongComType.ComTruc,
    name: "Cơm tối",
  },
];

export const EXacNhan = [
  {
    id: 1,
    name: "Đã duyệt",
    color: "green",
  },
  {
    id: 2,
    name: "Đã từ chối",
    color: "red",
  },
];
export const CachQuanLyEnum = {
  Ma: 1,
  SoLuong: 2,
};
export const CACH_QUAN_LY_OPTIONS = [
  {
    value: CachQuanLyEnum.Ma,
    name: "Quản lý theo mã",
  },
  {
    value: CachQuanLyEnum.SoLuong,
    name: "Quản lý theo số lượng",
  },
];
export const DonViTinhEnum = {
  Bao: 1,
  Binh: 2,
  Bo: 3,
  Cai: 4,
  Chai: 5,
  Chiec: 6,
  Cm: 7,
  Con: 8,
  Cuon: 9,
  Dieu: 10,
  Dm: 11,
  Gram: 12,
  Gio: 13,
  Goi: 14,
  Hop: 15,
  Ket: 16,
  Kg: 17,
  Kwh: 18,
  La: 19,
  Lan: 20,
  Lit: 21,
  Lo: 22,
  Met: 23,
  MetVuong: 24,
  MetKhoi: 25,
  Ml: 26,
  Mm: 27,
  Ngay: 28,
  Phut: 29,
  Qua: 30,
  Quyen: 31,
};

export const DON_VI_TINH_OPTIONS = [
  { value: DonViTinhEnum.Bao, name: "Bao" },
  { value: DonViTinhEnum.Binh, name: "Bình" },
  { value: DonViTinhEnum.Bo, name: "Bộ" },
  { value: DonViTinhEnum.Cai, name: "Cái" },
  { value: DonViTinhEnum.Chai, name: "Chai" },
  { value: DonViTinhEnum.Chiec, name: "Chiếc" },
  { value: DonViTinhEnum.Cm, name: "cm" },
  { value: DonViTinhEnum.Con, name: "Con" },
  { value: DonViTinhEnum.Cuon, name: "Cuốn" },
  { value: DonViTinhEnum.Dieu, name: "Điếu" },
  { value: DonViTinhEnum.Dm, name: "dm" },
  { value: DonViTinhEnum.Gram, name: "g" },
  { value: DonViTinhEnum.Gio, name: "Giờ" },
  { value: DonViTinhEnum.Goi, name: "Gói" },
  { value: DonViTinhEnum.Hop, name: "Hộp" },
  { value: DonViTinhEnum.Ket, name: "Két" },
  { value: DonViTinhEnum.Kg, name: "kg" },
  { value: DonViTinhEnum.Kwh, name: "Kwh" },
  { value: DonViTinhEnum.La, name: "Lá" },
  { value: DonViTinhEnum.Lan, name: "Lần" },
  { value: DonViTinhEnum.Lit, name: "Lít" },
  { value: DonViTinhEnum.Lo, name: "Lọ" },
  { value: DonViTinhEnum.Met, name: "m" },
  { value: DonViTinhEnum.MetVuong, name: "m²" },
  { value: DonViTinhEnum.MetKhoi, name: "m³" },
  { value: DonViTinhEnum.Ml, name: "ml" },
  { value: DonViTinhEnum.Mm, name: "mm" },
  { value: DonViTinhEnum.Ngay, name: "Ngày" },
  { value: DonViTinhEnum.Phut, name: "Phút" },
  { value: DonViTinhEnum.Qua, name: "Quả" },
  { value: DonViTinhEnum.Quyen, name: "Quyển" },
];
export const TrangThaiViTriEnum = {
  ChuaSuDung: 0,
  DangSuDung: 1,
  DeNghiBaoHong: 2,
  DaHong: 3,
  DangCapPhat: 4,
  DeNghiSuaChua: 5,
  DaSua: 6,
};
export const TRANG_THAI_VI_TRI_OPTIONS = [
  { value: TrangThaiViTriEnum.ChuaSuDung, name: "Chưa sử dụng" },
  { value: TrangThaiViTriEnum.DangSuDung, name: "Đang sử dụng" },
  { value: TrangThaiViTriEnum.DeNghiBaoHong, name: "Đề nghị báo hỏng" },
  { value: TrangThaiViTriEnum.DaHong, name: "Đã hỏng" },
  { value: TrangThaiViTriEnum.DangCapPhat, name: "Đang cấp phát" },
  { value: TrangThaiViTriEnum.DeNghiSuaChua, name: "Đề nghị sửa chữa" },
  { value: TrangThaiViTriEnum.DaSua, name: "Đã sửa" },
];
export const TrangThaiSuDungEnum = {
  BinhThuong: 0,
  DaMat: 1,
  DaHong: 2,
  DaHuy: 3,
};
export const TRANG_THAI_SU_DUNG_OPTIONS = [
  { value: TrangThaiSuDungEnum.BinhThuong, name: "Bình thường" },
  { value: TrangThaiSuDungEnum.DaMat, name: "Đã mất" },
  { value: TrangThaiSuDungEnum.DaHong, name: "Đã hỏng" },
  { value: TrangThaiSuDungEnum.DaHuy, name: "Đã huỷ" },
  { value: TrangThaiSuDungEnum.BaoHong, name: "Báo hỏng" },
  { value: TrangThaiSuDungEnum.BaoSua, name: "Báo sửa" },
];
export const CapPhatChoEnum = {
  NhanVien: 1,
  PhongBan: 2,
};
export const CAP_PHAT_OPTIONS = [
  { value: CapPhatChoEnum.NhanVien, name: "Nhân viên" },
  { value: CapPhatChoEnum.PhongBan, name: "Phòng ban" },
];

export const NGHIEP_VU_OPTIONS = [
  { value: 1, name: "Ghi tăng" },
  { value: 2, name: "Tạo cấp phát" },
  { value: 3, name: "Duyệt cấp phát" },
  { value: 4, name: "Từ chối cấp phát" },
];

export const ECampainExecuteStatus = {
  Pending: 0,
  Inprogress: 1,
  Stopped: 2,
  Finish: 3,
};
export const ECampainExecuteStatusData = [
  {
    id: ECampainExecuteStatus.Pending,
    name: "Chờ xử lý",
    color: "gray",
  },
  {
    id: ECampainExecuteStatus.Inprogress,
    name: "Đang xử lý",
    color: "orange",
  },
  {
    id: ECampainExecuteStatus.Stopped,
    name: "Tạm dừng",
    color: "red",
  },
  {
    id: ECampainExecuteStatus.Finish,
    name: "Kết thúc",
    color: "green",
  },
];

export const EMarketingType = [
  {
    value: 1,
    label: "Gọi tự động",
  },
  // {
  //   value: 2,
  //   label: "ZNS",
  // },
  // {
  //   value: 3,
  //   label: "My MEDIC",
  // }
];

export const EMarketingSendType = [
  {
    value: 0,
    label: "Nháp",
  },
  {
    value: 1,
    label: "Gửi ngay",
  },
  {
    value: 2,
    label: "Đặt lịch",
  },
];

export const ECallExecuteStatus = [
  {
    id: 0,
    name: "Chờ xử lý",
    color: "gray",
  },
  {
    id: 1,
    name: "Đang xử lý",
    color: "orange",
  },
  {
    id: 2,
    name: "Kết thúc",
    color: "green",
  },
  {
    id: 3,
    name: "Hủy",
    color: "green",
  },
  {
    id: 4,
    name: "Lỗi",
    color: "green",
  },
];

export const ECallResultStatus = [
  {
    value: 0,
    label: "Chưa gọi",
    color: "gray",
  },
  {
    value: 1,
    label: "Đang gọi",
    color: "blue",
  },
  {
    value: 2,
    label: "Đồng ý",
    color: "green",
  },
  {
    value: 3,
    label: "Tư vấn thêm",
    color: "orange",
  },
  {
    value: 4,
    label: "Không bấm phím",
    color: "gray",
  },
  {
    value: 5,
    label: "Không nhấc máy",
    color: "red",
  },
  {
    value: 6,
    label: "Lỗi cuộc gọi",
    color: "red",
  },
];

export const LOAITINHCHAT = {
  DuNo: 1,
  DuCo: 2,
  KhongCoSoDu: 3,
  LuongTinh: 4,
};
export const LOAITINHCHAT_OPTIONS = [
  { id: LOAITINHCHAT.DuNo, color: "red", name: "Dư nợ" },
  { id: LOAITINHCHAT.DuCo, color: "blue", name: "Dư có" },
  { id: LOAITINHCHAT.KhongCoSoDu, color: "green", name: "Không có số dư" },
  { id: LOAITINHCHAT.LuongTinh, color: "green", name: "Lưỡng tính" },
];

export const HINHTHUC_THANHTOAN = [
  { id: 1, color: "red", name: "Tiền mặt" },
  { id: 2, color: "red", name: "Chuyển khoản" },
];

export const LOAI_PHIEU_CHI = [
  { id: 1, color: "red", name: "Chi tiền khác" },
  { id: 2, color: "red", name: "Chi tiền trả nợ" },
  { id: 3, color: "red", name: "Chi tạm ứng" },
];

export const LOAI_PHIEU_THU = [
  { id: 1, color: "red", name: "Thu tiền khách hàng", viettat: "PTKH" },
  { id: 2, color: "blue", name: "Thu tiền khác", viettat: "PTK" },
  { id: 3, color: "red", name: "Thu hoàn ứng", viettat: "THU" },
];

export const LOAI_THU_CHI = {
  Thu: 1,
  Chi: 2,
};

export const LOAI_TIEN = [
  {
    id: 1,
    name: "VND",
  },
  { id: 2, name: "USD" },
];
export const LOAI_DANH_MUC_NGAM_DINH = [
  { id: 1, color: "red", name: "Danh mục thu chi" },
];
export const LOAI_DANH_MUC_QUY = [
  { id: 1, color: "red", name: "Quỹ tiền mặt" },
  { id: 2, color: "red", name: "Quỹ ngân hàng" },
];

export const TanSuatBaoDuong = [
  { id: 1, value: 1, label: "Một lần" },
  { id: 2, value: 2, label: "Định kỳ" },
];

export const XacDinhBaoDuong = [
  { id: 1, value: 1, label: "Theo thời gian" },
  { id: 2, value: 2, label: "Theo công suất" },
  { id: 3, value: 3, label: "Linh hoạt theo thời gian và công suất" },
];

export const ThoiDiemBaoDuong = [
  { id: 1, value: 1, label: "Sau ngày bắt đầu sử dụng" },
  { id: 2, value: 2, label: "Sau ngày mua" },
  { id: 3, value: 3, label: "Ngày tự chọn" },
];

export const BaoDuongLapLaiTheo = [
  { id: 1, value: 1, label: "Ngày" },
  { id: 2, value: 2, label: "Tuần" },
  { id: 3, value: 3, label: "Tháng" },
  { id: 4, value: 4, label: "Năm" },
];

export const DonViDoCongSuat = [
  { id: 1, value: 1, label: "Km" },
  { id: 2, value: 2, label: "Sản phẩm" },
];

export const KiemKeType = {
  KiemKeChiTiet: 1,
  KiemKeSoLuong: 2,
};

export const ACTION_KIEM_KE = {
  BatDauKiemKe: 10,
  XuatKhau: 11,
  NhapKhau: 12,
  HoanThanh: 13,
  XemChenhLech: 14,
  KiemKeLai: 15,
  InBienBan: 16,
  Huy: 17,
  Sua: 18,
  Xoa: 19,
  CapNhatSoLieuMoi: 20,
};

export const TRANG_THAI_KIEM_KE = [
  {
    value: 1,
    name: "Chưa kiểm kê",
    color: "",
    action: `${ACTION_KIEM_KE.BatDauKiemKe},${ACTION_KIEM_KE.XuatKhau},${ACTION_KIEM_KE.Sua},${ACTION_KIEM_KE.Xoa},${ACTION_KIEM_KE.CapNhatSoLieuMoi}`,
  },
  {
    value: 2,
    name: "Đang kiểm kê",
    color: "blue",
    action: `${ACTION_KIEM_KE.HoanThanh},${ACTION_KIEM_KE.XuatKhau},${ACTION_KIEM_KE.NhapKhau},${ACTION_KIEM_KE.Sua},${ACTION_KIEM_KE.Huy},${ACTION_KIEM_KE.CapNhatSoLieuMoi}`,
  },
  {
    value: 3,
    name: "Đã kiểm kê",
    color: "green",
    action: `${ACTION_KIEM_KE.XemChenhLech},${ACTION_KIEM_KE.XuatKhau},${ACTION_KIEM_KE.InBienBan},${ACTION_KIEM_KE.KiemKeLai}`,
  },
  {
    value: 4,
    name: "Đã hủy",
    color: "red",
    action: `${ACTION_KIEM_KE.XuatKhau},${ACTION_KIEM_KE.InBienBan},${ACTION_KIEM_KE.Xoa}`,
  },
];

export const TRANG_THAI_TAI_SAN_KIEM_KE = [
  {
    value: 1,
    name: "Chưa kiểm kê",
  },
  {
    value: 2,
    name: "Đã kiểm kê",
  },
];

export const KY_KIEM_KE = [
  { value: 1, label: "Tháng 1" },
  { value: 2, label: "Tháng 2" },
  { value: 3, label: "Tháng 3" },
  { value: 4, label: "Tháng 4" },
  { value: 5, label: "Tháng 5" },
  { value: 6, label: "Tháng 6" },
  { value: 7, label: "Tháng 7" },
  { value: 8, label: "Tháng 8" },
  { value: 9, label: "Tháng 9" },
  { value: 10, label: "Tháng 10" },
  { value: 11, label: "Tháng 11" },
  { value: 12, label: "Tháng 12" },
  { value: 100, label: "Quý 1" },
  { value: 200, label: "Quý 2" },
  { value: 300, label: "Quý 3" },
  { value: 400, label: "Quý 4" },
];

export const VAI_TRO_KIEM_KE = [
  { value: 1, name: "Trưởng ban kiểm kê" },
  { value: 2, name: "Thành viên kiểm kê" },
];

export const TRANG_THAI_XY_LY = [
  { value: 1, name: "Đã xử lý" },
  { value: 2, name: "Chưa xử lý" },
];

export const TINH_TRANG_TAI_SAN = [
  { value: 1, label: "Chưa sử dụng" },
  { value: 2, label: "Đang cấp phát" },
  { value: 3, label: "Đang sử dụng" },
  { value: 4, label: "Đang thu hồi" },
  { value: 5, label: "Đang báo hỏng" },
  { value: 6, label: "Đã Báo hỏng" },
  { value: 7, label: "Đã hỏng" },
  { value: 8, label: "Đang báo sửa" },
  { value: 9, label: "Đã báo sửa" },
  { value: 10, label: "Đang sữa chữa" },
  { value: 11, label: "Đang báo mất" },
  { value: 12, label: "Đã mất" },
  { value: 13, label: "Đang báo hủy" },
  { value: 14, label: "Đã hủy" },
  { value: 15, label: "Đã thanh lý" },
  { value: 16, label: "Đề nghị bảo dưỡng" },
  { value: 17, label: "Đang bảo dưỡng" },
  { value: 18, label: "Đang điều chuyển" },
];

export const KHACH_HANG_NCC = {
  KHACH_HANG: 1,
  NHA_CUNG_CAP: 2,
};

export const DIA_DIEM_BAO_DUONG = [
  { id: 1, label: "Tại đơn vị", value: 1 },
  { id: 2, label: "Nhà cung cấp", value: 2 },
  { id: 3, label: "Bên thuê/Bên mượn", value: 3 },
];

export const TRANG_THAI_BC_DAP_UNG = [
  { id: 1, name: "Không có đề xuất", color: "gray" },
  { id: 2, name: "Không đáp ứng", color: "red" },
  { id: 3, name: "Chưa đáp ứng hết", color: "orange" },
  { id: 4, name: "Đáp ứng 100%", color: "green" },
  { id: 5, name: "Đáp ứng trên 100%", color: "blue" },
];

export const LOAI_DE_XUAT = [
  { value: 1, label: "Đề xuất Tuần", color: "gray" },
  { value: 2, label: "Dự trù Tháng", color: "red" },
];
