import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import BaoCaoService from "services/sale/BaoCaoService";

export const getBaoCaoTonKho = createAsyncThunk(
  "BaoCao/getBaoCaoTonKho",
  async (data, { rejectWithValue }) => {
    try {
      const response = await BaoCaoService.getBaoCaoTonKho(data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const getBangKeNhap = createAsyncThunk(
  "BaoCao/getBangKeNhap",
  async (data, { rejectWithValue }) => {
    try {
      const response = await BaoCaoService.getBangKeNhap(data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const getBangKeXuat = createAsyncThunk(
  "BaoCao/getBangKeXuat",
  async (data, { rejectWithValue }) => {
    try {
      const response = await BaoCaoService.getBangKeXuat(data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const getBCDoanhThu = createAsyncThunk(
  "BaoCao/getBCDoanhThu",
  async (data, { rejectWithValue }) => {
    try {
      const response = await BaoCaoService.getBCDoanhThu(data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const getBCDapUngHH = createAsyncThunk(
  "BaoCao/getBCDapUngHH",
  async (data, { rejectWithValue }) => {
    try {
      const response = await BaoCaoService.getBCDapUngHH(data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);
export const ExportExcelBaoCaoDapUngHangHoa = createAsyncThunk(
  "BaoCao/ExportExcelBaoCaoDapUngHangHoa",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await BaoCaoService.ExportExcelBaoCaoDapUngHangHoa(
        payload
      );
      if (onSuccess) onSuccess(response);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);
export const ExportExcelBaoCaoDapUngHangHoaChiTiet = createAsyncThunk(
  "BaoCao/ExportExcelBaoCaoDapUngHangHoaChiTiet",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response =
        await BaoCaoService.ExportExcelBaoCaoDapUngHangHoaChiTiet(payload);

      if (onSuccess) onSuccess(response);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

const initialState = {
  loading: false,
  BaoCaotonList: [],
  BangKeNhapList: [],
  BangKeXuatList: [],
  BaoCaoDoanhThuList: [],
  BaoCaoDapUngHangHoaList: [],
};

export const BaoCaoSlice = createSlice({
  name: "BaoCao",
  initialState,
  reducers: {
    showLoading: (state) => {
      state.loading = true;
    },
    setAllDmkkList: (state, action) => {
      state.allDmKkList = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBCDapUngHH.pending, (state) => {
        state.loading = true;
      })
      .addCase(getBCDapUngHH.fulfilled, (state, action) => {
        state.loading = false;
        state.BaoCaoDapUngHangHoaList = action.payload;
      })
      .addCase(getBCDapUngHH.rejected, (state, action) => {
        state.loading = false;
      }) //
      .addCase(getBaoCaoTonKho.pending, (state) => {
        state.loading = true;
      })
      .addCase(getBaoCaoTonKho.fulfilled, (state, action) => {
        state.loading = false;
        state.BaoCaotonList = action.payload;
      })
      .addCase(getBaoCaoTonKho.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getBangKeNhap.pending, (state) => {
        state.loading = true;
      })
      .addCase(getBangKeNhap.fulfilled, (state, action) => {
        state.loading = false;
        state.BangKeNhapList = action.payload;
      })
      .addCase(getBangKeNhap.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getBangKeXuat.pending, (state) => {
        state.loading = true;
      })
      .addCase(getBangKeXuat.fulfilled, (state, action) => {
        state.loading = false;
        state.BangKeXuatList = action.payload;
      })
      .addCase(getBangKeXuat.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getBCDoanhThu.pending, (state) => {
        state.loading = true;
      })
      .addCase(getBCDoanhThu.fulfilled, (state, action) => {
        state.loading = false;
        state.BaoCaoDoanhThuList = action.payload;
      })
      .addCase(getBCDoanhThu.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export const { showLoading } = BaoCaoSlice.actions;

export default BaoCaoSlice.reducer;
