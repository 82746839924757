import axiosInstance from "auth/FetchInterceptor";
import { BAOCAO_API } from "constants/ApiConstant";
const BaoCaoService = {};

BaoCaoService.getBangKeNhap = function (data) {
  return axiosInstance.post(`${BAOCAO_API}/bang-ke-nhap`, data, {
    baseURL: process.env.REACT_APP_PHARMA_ENDPOINT_URL,
  });
};

BaoCaoService.getBangKeXuat = function (data) {
  return axiosInstance.post(`${BAOCAO_API}/bang-ke-xuat`, data, {
    baseURL: process.env.REACT_APP_PHARMA_ENDPOINT_URL,
  });
};

BaoCaoService.getBaoCaoTonKho = function (data) {
  return axiosInstance.post(`${BAOCAO_API}/bao-cao-ton-kho`, data, {
    baseURL: process.env.REACT_APP_PHARMA_ENDPOINT_URL,
  });
};
BaoCaoService.getBCDoanhThu = function (data) {
  return axiosInstance.post(`${BAOCAO_API}/bao-cao-doanh-thu`, data, {
    baseURL: process.env.REACT_APP_PHARMA_ENDPOINT_URL,
  });
};
BaoCaoService.getBCDapUngHH = function (data) {
  return axiosInstance.post(`${BAOCAO_API}/bao-cao-dap-ung-hh`, data, {
    baseURL: process.env.REACT_APP_SALE_ENDPOINT_URL,
  });
};
BaoCaoService.ExportExcelBaoCaoDapUngHangHoa = function (data) {
  return axiosInstance.post(`${BAOCAO_API}/export-bao-cao-dap-ung-hh`, data, {
    baseURL: process.env.REACT_APP_SALE_ENDPOINT_URL,
  });
};
BaoCaoService.ExportExcelBaoCaoDapUngHangHoaChiTiet = function (data) {
  return axiosInstance.get(
    `${BAOCAO_API}/export-bao-cao-dap-ung-hh-chitiet?DeXuatId=${data.deXuatId}`,
    {
      baseURL: process.env.REACT_APP_SALE_ENDPOINT_URL,
    }
  );
};

export default BaoCaoService;
