import axiosInstance from "auth/FetchInterceptor";
import { DANH_SACH_TRUONG_TOT_NGHIEP_API, TRINH_DO_HOC_VAN_UPSERT_API, TRINH_DO_HOC_VAN_DELETE_API, TRINH_DO_HOC_VAN_API } from "constants/ApiConstant";
const TrinhDoHocVanService = {};

TrinhDoHocVanService.getDanhSachTruongTotNghiep = function () {
    return axiosInstance.get(DANH_SACH_TRUONG_TOT_NGHIEP_API);
};
TrinhDoHocVanService.getByEmployeeId = function (id) {
    return axiosInstance.get(`${TRINH_DO_HOC_VAN_API}/detail/${id}`);
};
TrinhDoHocVanService.upsert = function (data) {
    return axiosInstance.post(TRINH_DO_HOC_VAN_UPSERT_API, data);
};
TrinhDoHocVanService.delete = function (id) {
    return axiosInstance.delete(`${TRINH_DO_HOC_VAN_DELETE_API}/${id}`);
};

export default TrinhDoHocVanService;
